import React, {useState} from 'react'
import {useQuery, useTranslate} from 'react-admin'
import {makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import {Edit} from '@material-ui/icons'
import Box from '@material-ui/core/Box'
import {Link} from 'react-router-dom'
import LinearProgress from '@material-ui/core/LinearProgress'
import i18nProvider, {languages} from '../../providers/i18nProvider'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import {ImagePreview} from '../ImagePreview/ImagePreview'

const useStyles = makeStyles(theme => ({
  root: {
    width: 560,
    maxWidth: 560,
    paddingTop: 48,
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100vw',
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: 16,
  },
  title: {
    flexGrow: 1,
  },
  body: {
    margin: 16,
  },
  imgWrapper: {
    margin: -8,
    display: 'flex',
    flexWrap: 'wrap',
  },
  img: {
    borderRadius: 4,
    maxWidth: 200,
    minWidth: 120,
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 140,
    margin: 8,
    height: 120,
    backgroundColor: '#ccc',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    '&:hover': {
      opacity: .8,
      cursor: 'pointer',
    }
  },
  fakeImg: {
    maxWidth: 200,
    minWidth: 120,
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 140,
    margin: `0 8px`,
  },
}))

export const PlacesMapEdit = ({onCancel, ...props}) => {
  const classes = useStyles()
  const [locale, setLocale] = useState(i18nProvider.getLocale())
  const [image, setImage] = useState(null)
  const translate = useTranslate()
  const {place} = props

  const {data, loading, error} = useQuery({
    type: 'getList',
    resource: 'places_bot_plugin/placeimage',
    payload: {
      filter: {place: place.id},
      pagination: {page: 1, perPage: 10000},
      sort: {field: 'id'}
    },
  })

  return (
    <div className={classes.root}>
      <div className={classes.toolbar}>
        <Typography className={classes.title} variant="h6">
          {place.name}
        </Typography>
        <IconButton onClick={props.onClose}>
          <CloseIcon/>
        </IconButton>
      </div>
      <div className={classes.body}>

        <Box mb={3}>
          <FormControl fullWidth variant="filled" className={classes.formControl}>
            <InputLabel id="lang-label">{translate('language._')}</InputLabel>
            <Select
              labelId="lang-label"
              value={locale}
              onChange={e => setLocale(e.target.value)}
            >
              {languages.map(lang => (
                <MenuItem key={lang.locale} value={lang.locale}>{translate(lang.name)}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Box mb={3}>
          <TextField
            fullWidth
            disabled
            value={place[`name_${locale}`]}
            InputLabelProps={{shrink: true}}
            label={translate('resources.places_bot_plugin/place.fields.name')}
          />
        </Box>

        <Box mb={3}>
          <TextField
            fullWidth
            multiline
            rows="8"
            disabled
            value={place[`description_${locale}`]}
            InputLabelProps={{shrink: true}}
            variant="outlined"
            label={translate('resources.places_bot_plugin/place.fields.description')}
          />
        </Box>

        <ImagePreview src={image} show={!!image} onClose={() => setImage(null)}/>

        <Box mb={3}>
          {loading && <LinearProgress/>}
          <div className={classes.imgWrapper}>
            {data && data.map(image => (
              <>
                <div
                  key={image.id}
                  onClick={() => setImage(image.image)}
                  className={classes.img}
                  style={{backgroundImage: `url(${image.image})`}}
                />
              </>
            ))}
            <div className={classes.fakeImg}/>
            <div className={classes.fakeImg}/>
            <div className={classes.fakeImg}/>
          </div>
        </Box>

        <Box mb={3}>
          <Button
            startIcon={<Edit/>}
            variant="contained"
            type="submit"
            color="primary"
            component={Link}
            to={`/places_bot_plugin/place/${place.id}`}
            target="_blank"
          >
            {translate('ra.action.edit')}
          </Button>
        </Box>
      </div>
    </div>
  )
}
