export default {
  ra: {
    boolean: {
      null: 'Не выбрано',
    },
  },

  language: {
    _: 'Язык',
    change: 'Сменить язык',
    english: 'Английский',
    russian: 'Русский',
  },

  chat: {
    button: {
      release: 'Отключиться',
      acquire: 'Взять в работу',
      enterText: 'Введите текст',
    }
  },

  map: {
    menu: 'Карта',
    places: {
      title: 'Места',
    }
  },

  charts: {
    menu: 'Статистика',
    channel: {
      title: 'Посетители',
      all: 'Пользователи',
      telegram: 'Telegram',
    },
    routes: {
      title: 'Маршруты',
    },
    screens: {
      title: 'Экраны',
    },
  },

  resources: {

    'infobot/action': {
      name: 'Action|||Actions',
      fields: {
        action: "Действие",
        action_args: "Агрументы",
      },
    },

    'infobot/user': {
      name: 'Сотрудник||||Сотрудники',
      menu: 'Сотрудники',
      change_password: "Изменить пароль",
      tabs: {
        main: "Основное",
        change_password: "Изменить пароль",
      },
      fields: {
        email: 'Email',
        first_name: "Имя",
        last_name: "Фамилия",
        patronymic: "Отчество",
        position: "Должность",
        is_operator: "Оператор",
        chat_operator_screen_name: "Отображаемое имя",
        groups: "Группы",
        new_password: "Новый пароль",
        confirm_password: "Повторите пароль",
      }
    },

    'chat_bot_plugin/chatoperator': {
      name: 'Оператор||||Операторы',
      all: 'Все операторы',
    },

    'faq_bot_plugin/answer': {
      name: 'FAQ||||FAQ',
      menu: 'FAQ',
      add_question: 'Добавить вопрос',
      edit_question: 'Изменить вопрос',
      tabs: {
        main: 'Основное',
        questions: 'Вопросы',
      },
      fields: {
        text: 'Текст',
        language: 'Язык',
        is_enabled: "Активен",
      },
    },

    'chat_bot_plugin/chatuser': {
      name: 'Чат||||Чаты',
      menu: 'Чаты',
      fields: {
        is_operator_calling: 'Вызывает оператора',
        is_dialog_active: 'Диалог активен',
        current_operator_screen_name: 'Оператор',
      },
    },

    'infobot/screen': {
      name: 'Экран||||Экраны',
      menu: 'Экраны',
      add_widget: 'Добавить виджет',
      fields: {
        iname: 'Внутреннее имя',
        view_count: 'Количество показов',
      },
    },

    'infobot/imagewidget': {
      name: 'Изображение||||Изображения',
      fields: {
        iname: 'Внутреннее имя',
      },
    },

    'infobot/htmlwidget': {
      name: 'Текст||||Тексты',
      fields: {
        iname: 'Внутреннее имя',
        html_ru: 'Текст (на русском)',
        html_en: 'Текст (на английском)',
      },
    },

    'infobot/buttonwidget': {
      name: 'Кнопка||||Кнопки',
      fields: {
        iname: 'Внутреннее имя',
        text_ru: 'Текст (на русском)',
        text_en: 'Текст (на английском)',
        go_to_screen: 'Переход на экран',
        action: 'Действие',
        action_args: 'Аргументы действия',
        order: 'Порядок',
      },
    },

    'infobot/position': {
      name: 'Должность||||Должности',
      menu: 'Должности',
      fields: {
        name_ru: 'Название (на русском)',
        name_en: 'Название (на английском)',
      },
    },

    'places_bot_plugin/place': {
      name: 'Место||||Места',
      menu: 'Места',
      add_path: 'Добавить соседнее место',
      add_image: 'Добавить изображение',
      change_image: 'Изменить изображение',
      tabs: {
        main: 'Основное',
        images: 'Изображения',
        location: 'Геоданные',
        paths: 'Соседние места',
      },
      fields: {
        name: 'Название',
        description: 'Описание',
        is_default: 'Стартовое',
        is_transit: 'Транзитное',
        name_ru: 'Название (на русском)',
        name_en: 'Название (на английском)',
        description_ru: 'Описание (на русском)',
        description_en: 'Описание (на английском)',
        address_ru: 'Адрес (на русском)',
        address_en: 'Адрес (на английском)',
        check_position_text_ru: 'Подсказать путь (на русском)',
        check_position_text_en: 'Подсказать путь (на английском)',
        web_address: 'Сайт',
        link: 'Ссылка',
        email: 'Email',
        phone: 'Телефон',
        longitude: 'Долгота',
        latitude: 'Широта'
      },
    },

    'places_bot_plugin/userplaceratereview': {
      name: 'Отзыв||||Отзывы',
      menu: 'Отзывы',
      all_places: 'Все места',
      fields: {
        rated_at: "Дата",
        rated_at__gte: "Дата с",
        rated_at__lt: "Дата по",
        bot_user_display_name: 'Клиент',
        text: 'Текст',
        rate: 'Оценка',
        channel: 'Канал',
        place: 'Место',
      }
    },

    'places_bot_plugin/route': {
      name: 'Маршрут||||Маршруты',
      menu: 'Маршруты',
      add_image: 'Добавить изображение',
      change_image: 'Изменить изображение',
      add_place: 'Добавить место',
      tabs: {
        main: 'Основное',
        images: 'Изображения',
        location: 'Геоданные',
        routes: 'Маршрут',
      },
      fields: {
        name: 'Имя',
        name_ru: 'Название (на русском)',
        name_en: 'Название (на английском)',
        description: 'Описание',
        description_ru: 'Описание (на русском)',
        description_en: 'Описание (на английском)',
        can_choose_start_place: 'Возможность выбора стартового места',
        is_one_way_mode: 'Автовыбор следующего места',
      },
    },

    'poll_bot_plugin/poll': {
      name: 'Опрос||||Опросы',
      menu: 'Опросы',
      tabs: {
        main: "Основное",
        questions: "Вопросы",
        answers: "Ответы",
      },
      add_question: 'Добавить вопрос',
      fields: {
        iname: 'Внутреннее имя',
        name_ru: 'Название (на русском)',
        name_en: 'Название (на английском)',
        description_ru: 'Описание (на русском)',
        description_en: 'Описание (на английском)',
        end_screen: 'End screen',
      },
    },

    'poll_bot_plugin/pollquestion': {
      name: 'Вопрос||||Вопросы',
      menu: 'Вопросы',
      add_question: 'Добавить вопрос',
      edit_question: 'Изменить вопрос',
      tabs: {},
      fields: {
      },
    },

    'poll_bot_plugin/pollanswer': {
      name: 'Ответ||||Ответы',
      menu: 'Ответ',
      tabs: {},
      fields: {
        created_at: 'Дата'
      },
    },

    'infobot/bot': {
      name: 'Бот||||Боты',
      menu: 'Боты',
      btnStart: 'Запустить',
      btnStop: 'Остановить',
      btnRestart: 'Перезапустить',
      fields: {
        iname: 'Название',
        start_screen: 'Начальный экран',
        menu_screen: 'Экран меню',
        telegram_token: 'Telegram token',
        maps_system: 'Система карт',
        maps_system_values: {
          native: 'Native',
          yandex: 'Yandex',
        },
        use_nlu_as_default_handler: 'Использовать робота для автоматических ответов на сообщения пользователя',
      }
    }

  }
}
