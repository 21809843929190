import React, {useEffect, useState} from 'react'
import {YandexMap} from '../YandexMap/YandexMap'
import {useQuery, useRedirect, useTranslate} from 'ra-core'
import {Drawer, makeStyles} from '@material-ui/core'
import {Route} from 'react-router-dom'
import {PlacesMapEdit} from './PlacesMapEdit'

const useStyles = makeStyles(theme => ({
  root: {
    padding: 16,
    width: '100%',
  },
  map: {
    margin: 0,
    padding: 0,
    minHeight: 420,
    [theme.breakpoints.up('md')]: {
      minHeight: `calc(100vh - 200px)`,
    },
  },
  drawerPaper: {
    zIndex: 100,
  },
}))

export const PlacesMap = props => {
  const [geoObjects, setGeoObjects] = useState({})
  const redirect = useRedirect()
  const classes = useStyles()

  const drawerClose = () => redirect('/map/places')

  const {data, loading, error} = useQuery({
    type: 'getList',
    resource: 'places_bot_plugin/place',
    payload: {
      filter: {},
      pagination: {page: 1, perPage: 10000},
      sort: {field: 'id'}
    },
  })

  const init = (map, ymaps) => {
    const geoObjects = {}

    data.forEach(place => {
      if (!place.latitude || !place.longitude) {
        return
      }
      const geoObject = new ymaps.GeoObject({
        geometry: {
          type: 'Point',
          coordinates: [place.latitude, place.longitude]
        },
        properties: {
          hintContent: place.name
        },
      }, {
        preset: 'islands#blueIcon',
        // draggable: true
      })

      geoObject.events.add('click', function (e) {
        Object.entries(geoObjects).forEach(([_, geoObject]) => {
          geoObject.options.set('preset', 'islands#blueIcon')
        })
        e.get('target').options.set('preset', 'islands#blueDotIcon')
        redirect(`/map/places/${place.id}`)
      })

      map.geoObjects.add(geoObject)
      geoObjects[place.id] = geoObject
    })

    switch (Object.keys(geoObjects).length) {
      case 0:
        break
      case 1:
        const geoObject = geoObjects[Object.keys(geoObjects)[0]]
        map.setCenter(geoObject.geometry.getCoordinates(), 12)
        break
      default:
        map.setBounds(map.geoObjects.getBounds())
    }

    setGeoObjects(geoObjects)
  }

  return (
    <Route path="/map/places/:id">
      {({match}) => {
        const place = match ? data?.find(place => place.id.toString() === match.params.id.toString()) : null

        const update = (map, ymaps) => {
          Object.entries(geoObjects).forEach(([_, geoObject]) => {
            geoObject.options.set('preset', 'islands#blueIcon')
          })
          if (place) {
            geoObjects[place.id].options.set('preset', 'islands#blueDotIcon')
          }
        }

        return (
          <>
            <div className={classes.root}>
              {!loading && <YandexMap className={classes.map} init={init} update={update}/>}
            </div>

            <Drawer
              variant="persistent"
              open={!!place}
              anchor="right"
              onClose={drawerClose}
              classes={{paper: classes.drawerPaper}}
            >
              {place && (
                <PlacesMapEdit
                  place={place}
                  onClose={drawerClose}
                />
              )}
            </Drawer>
          </>
        )
      }}
    </Route>
  )
}