import React from 'react'
import {Route} from 'react-router-dom'
import {Charts} from './components/Charts/Charts'
import {Map} from './components/Map/Map'

export default [
  <Route
    key="charts"
    path="/charts/:tab?"
    component={Charts}
  />,
  <Route
    key="map"
    path="/map/:tab?"
    component={Map}
  />,
]