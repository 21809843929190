import React from 'react'
import {List, Datagrid, TextField, BooleanField, ImageField, ReferenceManyField, SingleFieldList} from 'react-admin'
import {makeStyles} from '@material-ui/core/styles'
import ImageFieldPreview from '../../../components/ImageFieldPreview/ImageFieldPreview'

const useStyles = makeStyles({
  imgContainer: {
    padding: 16,
    '& img': {
      height: '100%',
      width: '100%',
      maxWidth: 240,
      maxHeight: 120,
      objectFit: 'contain',
      objectPosition: 'left',
      display: 'block',
      margin: 0,
    }
  }
})

const Image = props => {
  const classes = useStyles()
  let ids = props.ids

  if(props.ids && props.ids.length) {
    ids = props.ids.slice(0, 1)
  }

  return (
    <SingleFieldList {...props} ids={ids} linkType={false}>
      <ImageFieldPreview source="image" className={classes.imgContainer}/>
    </SingleFieldList>
  )
}

const Images = props => {
  return (
    <ReferenceManyField {...props} perPage={10000} label="Image" reference="places_bot_plugin/placeimage" target="place">
      <Image />
    </ReferenceManyField>
  )
}

export const placeList = props => {
  return (
    <List perPage={25} {...props} >
      <Datagrid rowClick="edit" st>
        {/*<TextField source="id"/>*/}
        <TextField source="name"/>
        {/*<BooleanField source="is_default"/>*/}

        <BooleanField source="is_transit"/>
        <Images/>
      </Datagrid>
    </List>
  )
}