import React, {useState} from 'react'
import {
  Edit,
  TextInput,
  BooleanInput,
  TabbedForm,
  useTranslate,
  FormTab, useDataProvider, useRedirect,
} from 'react-admin'
import Box from '@material-ui/core/Box'
import {makeStyles} from '@material-ui/core/styles'
import {PlaceImageList} from './tabs/PlaceImageList'
import {PlacePathList} from './tabs/PlacePathList'
import {PlaceMap} from './PlaceMap'

const useStyles = makeStyles(theme => ({
  plane: {
    width: '100%',
    maxWidth: 800
  },
}))

const updatePlaceImages = async (placesImages, dataProvider) => {
  if (placesImages && placesImages.length) {
    const ids = []
    const data = {
      needDataMap: true,
    }
    placesImages.forEach((image, index) => {
      ids.push(image.id)
      data[image.id] = {...image, order: index}
      delete data[image.id].image
      delete data[image.id].description
    })
    await dataProvider.updateMany('places_bot_plugin/placeimage', {ids, data})
  }
}

export const PlaceEdit = props => {
  const dataProvider = useDataProvider()
  const redirect = useRedirect()
  const classes = useStyles()
  const [placeImages, setPlaceImages] = useState([])
  const translate = useTranslate()

  return (
    <Edit undoable={false} {...props} >
      <TabbedForm redirect={async (url) => {
        await updatePlaceImages(placeImages, dataProvider)
        redirect(url)
      }}>
        <FormTab label={translate('resources.places_bot_plugin/place.tabs.main')}>
          <Box className={classes.plane}>

            <Box fullWidth display={{xs: 'block', sm: 'flex'}}>
              <Box className={classes.plane} flex={1} mx={{xs: 0, sm: 2}}>
                 <TextInput fullWidth source="name_ru" label={translate('resources.places_bot_plugin/place.fields.name_ru')} />
              </Box>

              <Box className={classes.plane} flex={1} ml={{xs: 0, sm: 2}}>
                 <TextInput fullWidth source="name_en" label={translate('resources.places_bot_plugin/place.fields.name_en')}/>
              </Box>
            </Box>

            <Box fullWidth display={{xs: 'block', sm: 'flex'}}>
              <Box className={classes.plane} flex={1} ml={{xs: 0, sm: 2}}>
                 <TextInput fullWidth source="web_address" label={translate('resources.places_bot_plugin/place.fields.web_address')}/>
             </Box>
              <Box className={classes.plane} flex={1} ml={{xs: 0, sm: 2}}>
                 <TextInput fullWidth source="link" label={translate('resources.places_bot_plugin/place.fields.link')}/>
              </Box>

            </Box>

             <Box fullWidth display={{xs: 'block', sm: 'flex'}}>
               <Box className={classes.plane} flex={1} ml={{xs: 0, sm: 2}}>
                 <TextInput fullWidth source="email" label={translate('resources.places_bot_plugin/place.fields.email')}/>
               </Box>
               <Box className={classes.plane} flex={1} ml={{xs: 0, sm: 2}}>
                 <TextInput fullWidth source="phone" label={translate('resources.places_bot_plugin/place.fields.phone')}/>
               </Box>
            </Box>

            <Box fullWidth display={{xs: 'block', sm: 'flex'}}>
              <Box className={classes.plane} flex={1} mx={{xs: 0, sm: 2}}>
                <TextInput fullWidth multiline rows="6" variant="outlined" source="description_ru" label={translate('resources.places_bot_plugin/place.fields.description_ru')}/>
              </Box>

              <Box className={classes.plane} flex={1} ml={{xs: 0, sm: 2}}>
                <TextInput fullWidth multiline rows="6" variant="outlined" source="description_en" label={translate('resources.places_bot_plugin/place.fields.description_en')}/>
              </Box>
            </Box>

            <Box fullWidth display={{xs: 'block', sm: 'flex'}}>
              <Box className={classes.plane} flex={1} mx={{xs: 0, sm: 2}}>
                <TextInput fullWidth multiline rows="6" variant="outlined" source="address_ru" label={translate('resources.places_bot_plugin/place.fields.address_ru')}/>
              </Box>

              <Box className={classes.plane} flex={1} ml={{xs: 0, sm: 2}}>
                <TextInput fullWidth multiline rows="6" variant="outlined" source="address_en" label={translate('resources.places_bot_plugin/place.fields.address_en')}/>
              </Box>
            </Box>

            <Box fullWidth display={{xs: 'block', sm: 'flex'}}>
              <Box className={classes.plane} flex={1} mx={{xs: 0, sm: 2}}>
                <TextInput fullWidth multiline rows="6" variant="outlined" source="check_position_text_ru" label={translate('resources.places_bot_plugin/place.fields.check_position_text_ru')}/>
              </Box>

              <Box className={classes.plane} flex={1} ml={{xs: 0, sm: 2}}>
                <TextInput fullWidth multiline rows="6" variant="outlined" source="check_position_text_en" label={translate('resources.places_bot_plugin/place.fields.check_position_text_en')}/>
              </Box>
            </Box>
          </Box>

          <Box fullWidth display={{xs: 'block', sm: 'flex'}}>
            <Box className={classes.plane} flex={1} mx={{xs: 0, sm: 2}}>
              <BooleanInput source="is_default"  label={translate('resources.places_bot_plugin/place.fields.is_default')}/>
              <BooleanInput source="is_transit"  label={translate('resources.places_bot_plugin/place.fields.is_transit')}/>
            </Box>
          </Box>
        </FormTab>

        <FormTab label={translate('resources.places_bot_plugin/place.tabs.location')} path={'map'}>
          <Box className={classes.plane} mx={{xs: 0, sm: 2}}>
            <Box display={{xs: 'flex', sm: 'flex'}}>
              <Box flex={1} mr={2}>
                <TextInput fullWidth label={translate('resources.places_bot_plugin/place.fields.longitude')} source="longitude" parse={e => e || null}/>
              </Box>
              <Box flex={1} ml={2}>
                <TextInput fullWidth label={translate('resources.places_bot_plugin/place.fields.latitude')} source="latitude" parse={e => e || null}/>
              </Box>
            </Box>
            <PlaceMap />
          </Box>
        </FormTab>

        <FormTab label={translate('resources.places_bot_plugin/place.tabs.images')} path={'image'}>
          <PlaceImageList {...props} placeImages={placeImages} setPlaceImages={setPlaceImages} />
        </FormTab>

        <FormTab label={translate('resources.places_bot_plugin/place.tabs.paths')} path={'path'}>
          <Box className={classes.plane}>
            <PlacePathList {...props} />
          </Box>
        </FormTab>

      </TabbedForm>
    </Edit>
  )
}
